export const advisoryBoard = [{
    id: 1,
    name: "Anas Rahman Junaid",
    designation: "Founder and MD of Hurun India",
    desc: "XMEC, Batch of 2005",
    imageUrl: require("../../Assets/team/anas.jpg"),
    linkedin: 'https://www.linkedin.com/in/anasrahmanjunaid',
},
{
    id: 2,
    name: "Jacob Pattara",
    designation: "AVP Product at PayTM",
    desc: "XMEC Batch of 2009. Started E-Cell MEC. Had a startup called WeavedIn, acquired by PayTM.",
    imageUrl: require("../../Assets/team/jacob.jpg"),
    linkedin: 'https://www.linkedin.com/in/jacobpattara',
},
{
    id: 3,
    name: "Samir Madhavan",
    designation: "Principal/Founder of Saitama Solutions",
    desc: "Author of popular book 'Mastering Python for Data Science'. E- Cell Chairman 2010. XMEC Batch of 2010",
    imageUrl: require("../../Assets/team/samir.jpg"),
    linkedin: 'https://www.linkedin.com/in/samir-madhavan-7b172115',
},
{
    id: 4,
    name: "Paul Thomas",
    designation: "Former Principal Software Consultant at Sequoia India",
    desc: "",
    imageUrl: require("../../Assets/team/paul_thomas.jpeg"),
    linkedin: 'https://www.linkedin.com/in/paul-thomas-b3241824/',
},
];

export const executiveMembers = [{
    id: 1,
    name: "Mrinalini Nair Ani",
    designation: "Chief Executive Officer",
    imageUrl: require("../../Assets/team/executives24-25/Mrinalini5.jpg"),
    linkedin: 'https://www.linkedin.com/in/mrinalini-nair-ani/',

},
{
    id: 2,
    name: "Lakshmi Sajilal",
    designation: "Chief Operating Officer",
    imageUrl: require("../../Assets/team/executives24-25/Lakshmi Sajilal - no background.png"),
    linkedin: 'https://www.linkedin.com/in/lakshmisajilal/',
},
{
    id: 3,
    name: "Mohammed Arshad",
    designation: "Chief Financial Officer",
    imageUrl: require("../../Assets/team/executives24-25/Mohammed Arshad .jpeg"),
    linkedin: 'https://www.linkedin.com/in/mohammed-arshad-developer/',
},
{
    id: 4,
    name: "Devadath K S",
    designation: "Chief Event Management Officer",
    imageUrl: require("../../Assets/team/executives24-25/devadath_ks.jpg"),
    linkedin: 'https://www.linkedin.com/in/devadathks/',
},
{
    id: 5,
    name: "Jerry Biji Mathew",
    designation: "Chief Marketing Officer",
    imageUrl: require("../../Assets/team/executives24-25/Jerry B Mathew .jpg"),
    linkedin: 'https://www.linkedin.com/in/jerrybmathew/',
},
{
    id: 6,
    name: "Subramani E",
    designation: "Chief Technology Officer",
    imageUrl: require("../../Assets/team/executives24-25/Subramani E.png"),
    linkedin: 'https://www.linkedin.com/in/subramani-e/',
},
{
    id: 7,
    name: "Shreya C H",
    designation: "Chief Content Officer",
    imageUrl: require("../../Assets/team/executives24-25/SHREYA C H.jpg"),
    linkedin: 'https://www.linkedin.com/in/shreya-c-h-96078822a/',
},
{
    id: 8,
    name: "Anavadya N Lakshmi",
    designation: "Chief Design Officer",
    imageUrl: require("../../Assets/team/executives24-25/Anavadya N Lakshmi.jpg"),
    linkedin: 'https://www.linkedin.com/in/anavadya-n-lakshmi-8a3475255/',
},
{
    id: 9,
    name: "Anagh R",
    designation: "Chief Social Media Officer",
    imageUrl: require("../../Assets/team/executives24-25/ANAGH R.jpg"),
    linkedin: 'https://www.linkedin.com/in/anaghrajeev/',
},
{
    id: 10,
    name: "Ann Sara John",
    designation: "Chief Publicity Officer",
    imageUrl: require("../../Assets/team/executives24-25/Ann_Sara_John.jpg"),
    linkedin: 'https://www.linkedin.com/in/ann-sara-john-aa630b256/',
},
{
    id: 11,
    name: "Srilakshmi R",
    designation: "Chief Project Management Officer",
    imageUrl: require("../../Assets/team/executives24-25/Srilakshmi R.jpg"),
    linkedin: 'https://www.linkedin.com/in/srilakshmi-ranganath-8b513923a',
},
{
    id: 12,
    name: "Amith Krishna A",
    designation: "Chief Social Responsibility Officer",
    imageUrl: require("../../Assets/team/executives24-25/AMITH KRISHNA A.jpg"),
    linkedin: 'https://www.linkedin.com/in/amith-krishna-6a2730227/',
},
{
    id: 13,
    name: "Devika Padmakumar Menon",
    designation: "Chief Community Officer",
    imageUrl: require("../../Assets/team/executives24-25/Devika.jpg"),
    linkedin: 'https://www.linkedin.com/in/devika-padmakumar-menon-525246230/',
},
{
    id: 14,
    name: "Samasya P Pramod",
    designation: "Talks Manager",
    imageUrl: require("../../Assets/team/executives24-25/Samasya P Pramod_.jpg"),
    linkedin: 'https://www.linkedin.com/in/samasya-p-pramod-a6a011220/',
},
{
    id: 15,
    name: "Hanna Ansar Koloth",
    designation: "Talks Manager",
    imageUrl: require("../../Assets/team/executives24-25/Hanna Ansar Koloth.jpg"),
    linkedin: 'https://www.linkedin.com/in/hanna-ansar-koloth-0a6523255/',
},
// {
//     id: 16,
//     name: "Mathew Kurian",
//     designation: "Operations Sub Head",
//     imageUrl: require("../../Assets/team/executives24-25/Mathew kurian.jpg"),
//     linkedin: 'https://www.linkedin.com/in/mathew-kurian-9ba07b25b/',
// },
];
export const ex_executiveMembers_2024 = [{

    id: 1,
    name: "Ananya Daniel",
    designation: "Chief Executive Officer",
    imageUrl: require("../../Assets/team/executives23-24/ananya.webp"),
    linkedin: 'https://www.linkedin.com/in/ananya-daniel-918073230/',

},
{
    id: 2,
    name: "Adhya Rajendran",
    designation: "Chief Operating Officer",
    imageUrl: require("../../Assets/team/executives23-24/adhya.webp"),
    linkedin: 'https://www.linkedin.com/in/adhya-rajendran-aab598200/',
},
{
    id: 3,
    name: "Nandana A R  ",
    designation: "Chief Financial Officer",
    imageUrl: require("../../Assets/team/executives23-24/nandana.webp"),
    linkedin: 'https://www.linkedin.com/in/nandana-a-r-987997250/',
},
{
    id: 4,
    name: "Jaison Dennis ",
    designation: "Chief Technology Officer",
    imageUrl: require("../../Assets/team/executives23-24/jaison.webp"),
    linkedin: 'https://www.linkedin.com/in/jaison080/ ',
},
{
    id: 5,
    name: "Alan George Mathews",
    designation: "Chief Project Management Officer",
    imageUrl: require("../../Assets/team/executives23-24/alan.webp"),
    linkedin: 'https://www.linkedin.com/in/alan-mathews-180222203/',
},
{
    id: 6,
    name: "Meenakshi S K ",
    designation: "Chief Marketing Officer",
    imageUrl: require("../../Assets/team/executives23-24/meenakshi.webp"),
    linkedin: 'https://www.linkedin.com/in/meenakshi-s-k-273633258/',
},
{
    id: 7,
    name: "Lakshmi Sajilal",
    designation: "Chief Digital Marketing Officer",
    imageUrl: require("../../Assets/team/executives24-25/Lakshmi Sajilal - no background.png"),
    linkedin: 'https://www.linkedin.com/in/lakshmisajilal/',
},
{
    id: 8,
    name: "Nikita Ann Mathen",
    designation: "Chief Creatives Officer",
    imageUrl: require("../../Assets/team/executives23-24/nikita.webp"),
    linkedin: 'https://www.linkedin.com/in/nikita-ann-mathen-b35111230/',
},
{
    id: 9,
    name: "Gayathri sasi ",
    designation: "Chief Publicity Officer",
    imageUrl: require("../../Assets/team/executives23-24/gayathri.webp"),
    linkedin: 'https://www.linkedin.com/in/gayathri-sasi-3b4a991bb/',
},
{
    id: 10,
    name: "Mrinalini Nair Ani",
    designation: "Chief Event Management Officer",
    imageUrl: require("../../Assets/team/executives23-24/mrinalini.webp"),
    linkedin: 'https://www.linkedin.com/in/mrinalini-nair-ani/',
},
{
    id: 11,
    name: "Lisa V Cherian ",
    designation: "Chief Social Responsibility Officer",
    imageUrl: require("../../Assets/team/executives23-24/lisa.webp"),
    linkedin: 'https://www.linkedin.com/in/lisa-v-cherian/',
},
{
    id: 12,
    name: "Akshay Varghese ",
    designation: "Chief Social Media Officer",
    imageUrl: require("../../Assets/team/executives23-24/akshay.webp"),
    linkedin: 'https://www.linkedin.com/in/akshay-varghese-671680267/',
},
{
    id: 13,
    name: "Devika Padmakumar Menon ",
    designation: "Talks Managers",
    imageUrl: require("../../Assets/team/executives23-24/devika.webp"),
    linkedin: 'https://www.linkedin.com/in/devika-padmakumar-menon-525246230/',
},
{
    id: 14,
    name: "Merin Vinoth ",
    designation: "Talks Managers",
    imageUrl: require("../../Assets/team/executives23-24/merin.webp"),
    linkedin: 'https://www.linkedin.com/in/merin-vinoth-3800b4258/',
},
{
    id: 15,
    name: "Vishnu C R",
    designation: "Maker head",
    imageUrl: require("../../Assets/team/executives23-24/vishnu.webp"),
    linkedin: 'https://www.linkedin.com/in/vishnu-c-r/',
}

];
export const ex_executiveMembers_2023 = [{
    id: 1,
    name: "Muhammed Razeen",
    designation: "Chief Executive Officer",
    imageUrl: require("../../Assets/team/Raz.jpg"),
    linkedin: 'https://www.linkedin.com/in/muhammed-razeen-298815190',

},
{
    id: 2,
    name: "Adithya A",
    designation: "Chief Operating Officer",
    imageUrl: require("../../Assets/team/adithya.jpg"),
    linkedin: 'https://www.linkedin.com/in/adithyaanilkumar/',
},
{
    id: 3,
    name: "Maria Rachel Joseph ",
    designation: "Chief Financial Officer",
    imageUrl: require("../../Assets/team/maria_rachel.jpg"),
    linkedin: 'https://www.linkedin.com/in/maria-rachel-joseph-4b80231a2/',
},
{
    id: 4,
    name: "Aldrin Jenson",
    designation: "Chief Technology Officer",
    imageUrl: require("../../Assets/team/aldrin.jpg"),
    linkedin: 'https://www.linkedin.com/in/aldrinjenson/',
},
{
    id: 5,
    name: "Ashwin Girish",
    designation: "Chief Design Officer",
    imageUrl: require("../../Assets/team/ashwin_girish.jpeg"),
    linkedin: 'https://www.linkedin.com/in/ashwin-girish-895276157',
},
{
    id: 6,
    name: "Sreehari Sreekumar",
    designation: "Chief Marketing Officer",
    imageUrl: require("../../Assets/team/sreehari_s.jpg"),
    linkedin: 'https://www.linkedin.com/in/sreehari-sreekumar-596597200',
},
{
    id: 7,
    name: "Gopika G ",
    designation: "Chief Social Media Officer",
    imageUrl: require("../../Assets/team/gopika_g.jpg"),
    linkedin: 'https://www.linkedin.com/in/gopika-gopi-02b204190',
},
{
    id: 8,
    name: "Zailesh A R",
    designation: "Chief Content Officer",
    imageUrl: require("../../Assets/team/Zailesh.jpeg"),
    linkedin: 'https://www.linkedin.com/in/zaileshar/',
},
{
    id: 9,
    name: "Nikita Menon",
    designation: "Chief Project Management Officer",
    imageUrl: require("../../Assets/team/nikita.jpg"),
    linkedin: 'https://www.linkedin.com/in/nikita-menon-b2248079/',
},
{
    id: 10,
    name: "Femina P.A",
    designation: "Chief Publicity Officer",
    imageUrl: require("../../Assets/team/femina_p_a.jpg"),
    linkedin: 'https://www.linkedin.com/in/femina-p-a-87b3631a6',
},
{
    id: 11,
    name: "Reva Pradeep",
    designation: "Social Initiatives Manager",
    imageUrl: require("../../Assets/team/reva_p.JPG"),
    linkedin: 'https://www.linkedin.com/in/reva-pradeep-248546202',
},
{
    id: 12,
    name: "Ananya Daniel",
    designation: "Chief Event Management Officer",
    imageUrl: require("../../Assets/team/ananya_d.jpeg"),
    linkedin: 'https://www.linkedin.com/in/ananya-daniel-918073230/',
},
{
    id: 13,
    name: "John Tharian",
    designation: "Chief Community Officer",
    imageUrl: require("../../Assets/team/john_t.jpg"),
    linkedin: 'https://www.linkedin.com/in/johntharian',
},
{
    id: 14,
    name: "Annamma T Philip",
    designation: "Talks Manager",
    imageUrl: require("../../Assets/team/annamma_t_p.jpg"),
    linkedin: 'https://www.linkedin.com/in/annamma-philip-929845202',
},
{
    id: 15,
    name: "Sudeep S",
    designation: "Talks Manager",
    imageUrl: require("../../Assets/team/sudeep_s.jpeg"),
    linkedin: 'https://www.linkedin.com/in/sudeep-s-65272b229',
},
{
    id: 16,
    name: "Anjana Sudevan",
    designation: "SIG Head",
    imageUrl: require("../../Assets/team/anjana_s.jpg"),
    linkedin: 'https://www.linkedin.com/in/anjana-sudevan-7b54031aa',
}
];

export const ex_executiveMembers_2022 = [{
    id: 1,
    name: "Namith Krishnan",
    designation: "Chief Executive Officer",
    imageUrl: require("../../Assets/team/namith.jpeg"),
    linkedin: 'https://www.linkedin.com/in/a-namith-krishnan-4589291a7',

},
{
    id: 2,
    name: "Niranjana",
    designation: "Chief Operating Officer",
    imageUrl: require("../../Assets/team/niranjana.jpg"),
    linkedin: 'https://www.linkedin.com/in/niranjana-b-nair-48b976203',
},
{
    id: 3,
    name: "Amalraj K",
    designation: "Chief Financial Officer",
    imageUrl: require("../../Assets/team/amalraj.jpg"),
    linkedin: 'https://www.linkedin.com/in/amalraj-k-ab30b71a1',
},
{
    id: 4,
    name: "Rindish Krishna",
    designation: "Chief Technology Officer",
    imageUrl: require("../../Assets/team/rindish.jpg"),
    linkedin: 'https://www.linkedin.com/in/123vivekr/',
},
{
    id: 5,
    name: "Abhiram Anil",
    designation: "Chief Design Officer",
    imageUrl: require("../../Assets/team/Abhiram.jpeg"),
    linkedin: 'https://www.linkedin.com/in/abhiram-anil-55b9211a7',
},
{
    id: 6,
    name: "Muhammed Razeen",
    designation: "Chief Marketing Officer",
    imageUrl: require("../../Assets/team/Raz.jpg"),
    linkedin: 'https://www.linkedin.com/in/muhammed-razeen-298815190',
},
{
    id: 7,
    name: "Neetha Jayan",
    designation: "Chief Social Media Officer",
    imageUrl: require("../../Assets/team/neetha.jpeg"),
    linkedin: 'https://www.linkedin.com/in/neetha-jayan-b271161b3',
},
{
    id: 8,
    name: "Aswathy S Menon",
    designation: "Chief Content Officer",
    imageUrl: require("../../Assets/team/aswathy.jpeg"),
    linkedin: 'https://www.linkedin.com/in/aswathy-s-menon',
},
{
    id: 9,
    name: "Nikita Menon",
    designation: "Chief Project Managament Officer",
    imageUrl: require("../../Assets/team/nikita.jpg"),
    linkedin: 'https://www.linkedin.com/in/nikita-menon-b2248079/',
},
{
    id: 10,
    name: "Denil Geo Daise",
    designation: "Head of Outreach & Public Relations",
    imageUrl: require("../../Assets/team/denil.jpg"),
    linkedin: 'https://www.linkedin.com/in/denil-daise-3215521a7',
},
{
    id: 11,
    name: "Malavika R Vikraman",
    designation: "Head of Social Responsibilty",
    imageUrl: require("../../Assets/team/malavika.jpg"),
    linkedin: 'https://www.linkedin.com/in/malavika-rajesh-vikraman',
},
{
    id: 12,
    name: "Adithya Anilkumar",
    designation: "Chief Event Management Officer",
    imageUrl: require("../../Assets/team/adithya.jpg"),
    linkedin: 'https://www.linkedin.com/in/adithyaanilkumar/',
},
{
    id: 13,
    name: "Abhiram Vijayakumar",
    designation: "Chief Community Officer",
    imageUrl: require("../../Assets/team/abhiram.jpg"),
    linkedin: 'https://www.linkedin.com/in/abhiram-vijayakumar-9b95381a7',
},
{
    id: 14,
    name: "Vishnumaya S Unni",
    designation: "Talks Manager",
    imageUrl: require("../../Assets/team/vishnumayaunni.jpeg"),
    linkedin: 'https://www.linkedin.com/in/vishnumayasunni',
},
{
    id: 15,
    name: "Sharat Jacob Jacob",
    designation: "Talks Manager",
    imageUrl: require("../../Assets/team/sharat.jpeg"),
    linkedin: 'https://www.linkedin.com/in/sharat-jacob-jacob-045aa219b',
},
{
    id: 16,
    name: "Maria Rachel Joseph",
    designation: "SIG Head",
    imageUrl: require("../../Assets/team/maria.jpeg"),
    linkedin: 'https://www.linkedin.com/in/maria-rachel-joseph-4b80231a2/',
}
];



export const ex_executiveMembers_2021 = [{
    id: 1,
    name: "Allen Joseph",
    designation: "Chief Executive Officer",
    imageUrl: require("../../Assets/team/allen.jpg"),
    linkedin: 'https://www.linkedin.com/in/allen-joseph-b72221124/',
},
{
    id: 2,
    name: "Ansif T A",
    designation: "Chief Operating Officer",
    imageUrl: require("../../Assets/team/ansif.jpg"),
    linkedin: 'https://www.linkedin.com/in/ansif/',
},
{
    id: 3,
    name: "Aswin Shanil",
    designation: "Chief Financial Officer",
    imageUrl: require("../../Assets/team/aswin.jpg"),
    linkedin: 'https://www.linkedin.com/in/aswin-shanil-150a55160/',
},
{
    id: 4,
    name: "Vivek R",
    designation: "Chief Technology Officer",
    imageUrl: require("../../Assets/team/vivek.jpg"),
    linkedin: 'https://www.linkedin.com/in/123vivekr/',
},
{
    id: 5,
    name: "Harinarayanan Shaji",
    designation: "Chief Design Officer",
    imageUrl: require("../../Assets/team/hari.jpg"),
    linkedin: 'https://www.linkedin.com/in/harinarayanan-shaji/',
},
{
    id: 6,
    name: "Alan Sebastian",
    designation: "Chief Marketing Officer",
    imageUrl: require("../../Assets/team/alan.jpg"),
    linkedin: 'https://www.linkedin.com/in/alan-sebastian-george-09172615b/',
},
{
    id: 7,
    name: "Medha Lakshman Rao",
    designation: "Chief Social Media Officer",
    imageUrl: require("../../Assets/team/medha.jpg"),
    linkedin: 'https://www.linkedin.com/in/medha-lakshman-rao-8138aa186/',
},
{
    id: 8,
    name: "Suvarna Sivadas",
    designation: "Chief Content Officer",
    imageUrl: require("../../Assets/team/suvarna.jpg"),
    linkedin: 'https://www.linkedin.com/in/suvarnasivadas/',
},
{
    id: 9,
    name: "Ashmi Fathima",
    designation: "Chief Publicity Officer",
    imageUrl: require("../../Assets/team/ashmi.jpg"),
    linkedin: 'https://www.linkedin.com/in/ashmi-fathima/',
}
];

export const webTeam = [{
    id: 1,
    name: "Nikita Menon",
    designation: "Webmaster",
    imageUrl: require("../../Assets/team/nikita.jpg"),
    linkedin: 'https://www.linkedin.com/in/nikita-menon-b2248079/',
},
{
    id: 2,
    name: "Adithya Anilkumar",
    designation: "Webmaster",
    imageUrl: require("../../Assets/team/adithya.jpg"),
    linkedin: 'https://www.linkedin.com/in/adithyaanilkumar/',
},
{
    id: 3,
    name: "Aldrin Jenson",
    designation: "Webmaster",
    imageUrl: require("../../Assets/team/aldrin.jpg"),
    linkedin: 'https://www.linkedin.com/in/aldrinjenson/',
},
{
    id: 4,
    name: "Arti Bhatia",
    designation: "Full Stack Developer",
    imageUrl: require("../../Assets/team/arti.jpg"),
    linkedin: 'https://www.linkedin.com/in/arti-bhatia/',
},
{
    id: 5,
    name: "Ameen",
    designation: "Full Stack Developer",
    imageUrl: require("../../Assets/team/ameen.jpg"),
    linkedin: 'https://www.linkedin.com/in/ameen-azeez/',
},
{
    id: 6,
    name: "Rindish Krishna",
    designation: "Full Stack Developer",
    imageUrl: require("../../Assets/team/rindish.jpg"),
    linkedin: 'https://www.linkedin.com/in/rindishkrishna/',
},
{
    id: 7,
    name: "Maria Rachel Joseph",
    designation: "Front End Developer",
    imageUrl: require("../../Assets/team/maria.jpeg"),
    linkedin: 'https://www.linkedin.com/in/maria-rachel-joseph-4b80231a2/',
},
{
    id: 8,
    name: "Vishruth S",
    designation: "Front End Developer",
    imageUrl: require("../../Assets/team/vishruth.jpg"),
    linkedin: 'www.linkedin.com/in/vishruth-subramanian/',
},
{
    id: 9,
    name: "Ashwin Girish",
    designation: "Front End Developer",
    imageUrl: require("../../Assets/team/ashwin.jpeg"),
    linkedin: 'https://www.linkedin.com/in/ashwin-girish-895276157/',
},
{
    id: 10,
    name: "Khushi Shah",
    designation: "Front End Developer",
    imageUrl: require("../../Assets/team/khushi.jpeg"),
    linkedin: 'https://www.linkedin.com/in/khushi-bharat-kumar-shah-7a94031aa/',
},
{
    id: 11,
    name: "Ranjana",
    designation: "Front End Developer",
    imageUrl: require("../../Assets/team/ranjana.jpg"),
    linkedin: 'https://www.linkedin.com/in/ranjana-reghunath/',
},
];

